<template>
    <div class="auth">
        <h1 class="auth__caption">
            Amway content CMS
        </h1>
        <v-form v-model="valid" @submit.prevent="validate" ref="auth" class="auth__form">
            <v-text-field
                outlined
                class="mb-6"
                label="Логин"
                autofocus
                v-model="login"
                :error="errorAlert"
                :rules="[Rules.notEmpty]"
                append-icon="mdi-account-outline"
                hide-details="true">
            </v-text-field>
            <v-text-field
                outlined
                :append-icon="showPass ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                label="Пароль"
                :type="showPass ? 'text' : 'password'"
                v-model="pass"
                autofocus
                :error="errorAlert"
                :rules="[Rules.notEmpty]"
                @click:append="showPass = !showPass"
                hide-details="true"
                class="auth__password">
            </v-text-field>
            <div class="auth__button">
                <v-btn
                    elevation="0"
                    color="#0A467E"
                    dark
                    tile
                    type="submit"
                    height="44"
                    class="mt-11 px-10"
                    >
                        Вход
                </v-btn>
            </div>
            
        </v-form>
        
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
    name: "Auth",
    data: () => ({
        showPass: false,
        login: '',
        pass: '',
        valid: true,
    }),
    computed: {
        ...mapGetters(['User', 'Rules', 'getAuthErrors']),
        errorAlert() {
            return (this.getAuthErrors && this.getAuthErrors.length > 0)
        }
    },
    methods: {
        ...mapActions(['autorization']),
        async validate () {
            this.$refs.auth.validate()
            if(this.valid) {
                await this.autorization({
                    login: this.login,
                    pass: this.pass
                })
                if(this.User && Object.keys(this.User).length) {
                    this.$router.push({ 
                        name: "PromoList",
                        params: { country: 'ru' } 
                    })
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
    .auth {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media screen and (max-width: 600px) {
            padding: 0 15px;
        }

        &__form {
            width: 340px;
            @media screen and (max-width: 1199px) {
               width: 360px;
            }
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }

        &__caption {
            margin-bottom: 24px;
            font-family: "Roboto Condensed", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 38px;
            text-align: center;
            color: #0A467E;
        }

        &__input {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #858585;
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
<style lang="scss">
    .auth {
        &__password {
            & .v-icon {
                transition: .3s;
                &:hover {
                    color: #008FC5;
                }
            }
        } 
    }
</style>